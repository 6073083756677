import { API, authClient } from "../index";

// Get active session
export const getSession = () => {
  return authClient().get(`${API}/session`);
};

//logout
export const logout = () => {
  return authClient().get(`${API}/session/logout`);
};

//Get first token
export const getFirstToken = () => {
  return authClient().get(`${API}/session/firstToken`);
};

// Create session
export const postSession = (oauthverifier, oauth_token, oauth_token_secret) => {
  const body = { oauthverifier, oauth_token, oauth_token_secret };
  return authClient().post(`${API}/session/createtoken`, body);
};

//Get all necessary data and generate the excel file
export const generateExcel = (dateini) => {
  const body = { dateini };
  return authClient().post(`${API}/session/datatable`, body);
};
