import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";

import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import {
  generateExcel,
  getFirstToken,
  getSession,
  postSession,
  logout,
} from "../../../../api/session";

import { getProfileById } from "../../../../api/profile";
import { downloadExcel } from "../../../../utils/helpers.js";

import { alertError, alertSuccess } from "../../../../utils/logger";
import { useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

export default function CustomersPage() {
  const user = useSelector((store) => store.authentication?.user, shallowEqual);
  const [profile, setProfile] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [data, setData] = useState([]);
  const [session, setSession] = useState(false);
  const [fechaIni, setFechaIni] = useState("");
  const [fechaFin, setFechaFin] = useState("");

  const handleFechaChange = (event) => {
    if (event.target.id === "fechaIni") setFechaIni(event.target.value);
    else if (event.target.id == "fechaFin") setFechaFin(event.target.value);
  };

  const handleFechaButton = (date) => {
    if (date === "Ultimos 24 meses") {
      const fechaActual = new Date(); // Obtener la fecha actual

      // Restar 24 meses a la fecha actual
      fechaActual.setMonth(fechaActual.getMonth() - 24);

      const year = fechaActual.getFullYear();
      const month = (fechaActual.getMonth() + 1).toString().padStart(2, "0");
      const day = fechaActual
        .getDate()
        .toString()
        .padStart(2, "0");

      const fechaFormateada = `${year}-${month}-${day}`;
      setFechaIni(fechaFormateada);
      setFechaFin("");
    }
  };

  useEffect(() => {
    let isMounted = true;
    const obtainSession = async () => {
      try {
        const res = await getSession();
        if (isMounted && res.status === 200) {
          setSession(true);
        }
      } catch (error) {
        if (isMounted) {
          //Ja veurem que fem aquí
        }
        //console.error(error);
        // alertError({
        //   error: error,
        //   customMessage: "Could not get .",
        // });
      }
      if (user.profile) {
        getProfileById(user?.profile).then((res) => {
          if (res.status === 200) {
            setProfile(res.data.permissions);
          }
        });
      }
    };
    obtainSession();
    return () => {
      isMounted = false;
    };
  }, []);

  async function createExcel() {
    //Fer crida funció per generar l'excel perquè tenim una sessió iniciada
    setSpinner(true);
    generateExcel(fechaIni)
      .then((res) => {
        setSpinner(false);
        alertSuccess({
          title: "Generated!",
          customMessage: "Excel Generted Successfully",
        });
        if (res.status === 200) {
          setData(res.data);
          downloadExcel(res.data);
        }
      })
      .catch((err) => {
        // alertError({  title: 'Ups!', customMessage: 'Could not generate the excel, please contact with support team' + err })
        console.error(err);
        setSpinner(false);
      });
  }

  async function fetchData() {
    getFirstToken()
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          //Començar amb el procés de login per activar una sessió
          console.log(res.data);
          const { oauth_token, oauth_token_secret } = res.data.headers;
          console.log(
            "OAUTH_TOKEN:",
            oauth_token,
            "OAUTH_SECRET_TOKEN",
            oauth_token_secret
          );
          const url = `https://anfix.com/login-partner/?oauth_token=${oauth_token}`;
          const url2 = new URL(`http://localhost:3000/`);
          let oauthverifier = "";
          const opciones =
            "width=500,height=500,resizable,scrollbars=yes,status=1";

          const popup = window.open(url, "_blank", opciones);

          const timer = setInterval(function() {
            try {
              if (popup.location.href.includes("localhost:3000")) {
                clearInterval(timer);
                const searchParams = new URLSearchParams(popup.location.search);
                oauthverifier = searchParams.get("oauth_verifier");
                console.log("VERIFIERTOKEN", oauthverifier);
                if (oauthverifier && oauthverifier.length != 0) {
                  clearInterval(timer);
                  postSession(
                    oauthverifier,
                    oauth_token,
                    oauth_token_secret
                  ).then((res) => {
                    if (res.status === 201) {
                      popup.close();
                      setSession(true);
                      window.location.reload();
                    }
                  });
                }
              }
            } catch (e) {
              //console.error(e);
              // Manejo de excepciones para evitar errores debido a restricciones de seguridad de origen cruzado
            }
          }, 1000);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not generate the first Token.",
        });
      });
  }

  async function Anfixlogout() {
    const res = await logout();
    if (res.status === 200) {
      alertSuccess({
        customMessage: "Logged out successfully",
      });
      window.location.reload();
    } else {
      alertError({
        customMessage: "Could not logout.",
      });
    }
  }

  const Spinner = spinner ? (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1,
      }}
    >
      <div className="d-flex jusitfy-center align-center">
        <div
          className="spinner-grow"
          style={{ width: "5rem", height: "5rem", marginTop: "20rem" }}
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );

  if (profile.includes("excelAccess"))
    return (
      <>
        {session && (
          <Card>
            <CardHeader title="Generate Table Data" />
            <CardBody style={{ position: "relative", padding: "20px" }}>
              {Spinner}
              <div style={{ maxWidth: "400px", margin: "auto" }}>
                <div style={{ marginBottom: "20px" }}>
                  <label htmlFor="fechaIni" className="form-label">
                    Start Date:
                  </label>
                  <input
                    type="date"
                    className="form-control py-2 my-2"
                    style={{ cursor: "pointer" }}
                    id="fechaIni"
                    value={fechaIni}
                    onChange={handleFechaChange}
                    placeholder="Select a start date"
                  />
                </div>
                <button
                  type="button"
                  className="btn btn-primary btn-block"
                  onClick={() => createExcel()}
                  disabled={!fechaIni}
                  style={{ width: "100%", padding: "10px 0" }}
                >
                  Generate New Excel
                </button>
              </div>
            </CardBody>
          </Card>
        )}
        {!session && (
          <button
            type="button"
            className="btn btn-primary py-1 my-2"
            onClick={() => fetchData()}
            disabled={!fechaIni}
          >
            New Anfix Session
          </button>
        )}
      </>
    );
  else
    return (
      <>
        Sorry, you do not have access permissions. Ask an administrator to
        access this section
      </>
    );
}
